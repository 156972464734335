.builder{
    width: 1600px;
    margin: 0 auto;
    position: relative;
}


.left-col{
    display: inline-block;
    vertical-align: top;
    position: sticky;
    width: 25%;
    top: 0;
    margin-right: 1.5em;
}

.left-col-lg{

}

.right-col{
    display: inline-block;
    width: calc(75% - 1.5em);
}

.right-col-lg{

}

.index-selector-wrapper{
    box-shadow: 0px 0px 5px black;
    background-color: #181818;
    display: inline-block;
    width: 70%;
}
.settings-wrapper{
    box-shadow: 0px 0px 5px black;
    background-color: #181818;
    display: inline-block;
    margin-left: 0.5em;
    width: calc(30% - 0.5em);
}

.index-and-settings-wrapper{
    margin-bottom: 0.5em;
    height: ;
}

.trades-wrapper{
    box-shadow: 0px 0px 5px black;
    background-color: #181818;
    margin-bottom: 1em;
}

.strategy-trade-box-wrapper{
    box-shadow: 0px 0px 5px black;
    background-color: #181818;
    margin-bottom: 0.5em;
}

.info-wrapper{
    box-shadow: 0px 0px 5px black;
    background-color: #181818;

}

.key-results-wrapper{
    box-shadow: 0px 0px 5px black;
    background-color: #181818;
}

.builder-chart-wrapper{
    
    box-shadow: 0px 0px 5px black;
    background-color: #181818;
    margin-top: 1em;
}

.strategy-options-wrapper{

    box-shadow: 0px 0px 5px black;
    background-color: #181818;
    margin-top: 1em;

}