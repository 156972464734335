.ticker-panel-wrapper {
    top: auto;
    bottom: 0;
    height: calc(100vh - 70px);
    max-width: calc(400px);
    width: 25%;
    position: fixed;
    display: inline-block;
    box-shadow: 0px 0px 5px #000000;
}

.chart-header-wrapper{
    z-index: 1046;
    background-color: #181818;
    height: 70px;
    box-shadow: 0px 0px 5px #000000;
}

.ticker-panel-wrapper-lg{
    margin-top: 70px;
}

.chart-wrapper{
    max-width: 1200px;
    width: 75%;
}

.offcanvas-lg{
    color: unset;
}

.bg-raisin-black{
    background-color: #181818;
}