.leg{
  box-shadow: -2px 2px 10px 2px rgb(0, 122, 153);
  color: black;
  background-color: rgb(255, 255, 255);
}

.buy{
    background-color: green;
}
.sell{
  background-color: darkred;
}