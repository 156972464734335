.ticker-card-wrapper{
    height: 45px;
    border-bottom: 1px solid rgb(77, 77, 82, 0.3);
    cursor: pointer
}

.dragging{
    cursor: grabbing;
}

.small-scroll::-webkit-scrollbar {
    width: 5px;
  }

.watchlist-button-parent{
    height: 45px;
    border-top: 1px solid rgb(77, 77, 82, 0.5);
    border-bottom: 1px solid rgb(77, 77, 82, 0.5);
}

.watchlist-button{
    border-right: 1px solid rgb(77, 77, 82, 0.3);
    width: 3em;
    height: 100%;
}

.bg-watchlist{
    background-color: #181818;
}

.bg-watchlist-active{
    background-color: #0f0f0f;
}

.watchlist-button:hover{
    background-color: #141414;
}

.watchlist-settings-gear{
    margin: auto;
    margin-left: 2em;
    margin-right: 0.7em;
}

.watchlist-settings-popover{
    
    background-color: #242424;
    box-shadow: 0px 0px 5px #000000;
}

.strategy-summary-wrapper{
    padding: 10px;
    box-shadow: inset 0px 0px 40px -25px #23B5B5 ;
}